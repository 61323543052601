<template>
  <div
    class="animal-actions d-flex justify-content-start align-items-center flex-column"
  >
    <portal to="animal-actions-button">
      <b-button
        variant="primary"
        @click="
          $store.commit(
            'animalsModule/SET_EDIT_SIDEBAR',
            !$store.state.animalsModule.isEditAnimalSidebarActive
          )
        "
      >
        <span class="text-nowrap">{{ $t("editAnimal") }}</span>
      </b-button>
    </portal>
    <b-row
      class="flex-grow-1 justify-content-start align-items-start row-eq-height"
      style="max-width: 100%"
    >
      <b-col cols="12" sm="6" md="4" lg="3" class="p-0 px-sm-1 col-xxl-2">
        <animal-action
          :title="$t('status')"
          :subtitles="[
            animal.statusdate
              ? dayjs(animal.statusdate)
                  .locale(`${$i18n.locale}`)
                  .format('DD.MM.YYYY dddd')
              : '',

            animal.statusname ? animal.statusname : $t('undefined'),
            animal.statusgun ? animal.statusgun : 0,
          ]"
          :to="{ name: 'animal-status', params: { id: animal.id } }"
        />
      </b-col>
      <b-col
        v-if="animal.genderid == 1"
        cols="12"
        sm="6"
        md="4"
        lg="3"
        class="p-0 px-sm-1 col-xxl-2"
      >
        <animal-action
          :title="$t('milk')"
          :subtitles="[
            animal.lactation
              ? $t('lactation') + ': ' + animal.lactation
              : $t('undefined'),
            ` ${
              animal.aveGunlukSut
                ? $t('dailyMilk') + ': ' + animal.aveGunlukSut
                : $t('undefined')
            }`,
            ` ${
              animal.aveHaftalikSut
                ? $t('weeklyMilk') + ': ' + animal.aveHaftalikSut
                : $t('undefined')
            }`,
          ]"
          :to="{ name: 'animal-milk', query: { id: animal.id } }"
        />
      </b-col>
      <b-col
        v-if="animal.genderid == 1"
        cols="12"
        sm="6"
        md="4"
        lg="3"
        class="p-0 px-sm-1 col-xxl-2"
      >
        <animal-action
          :title="$t('insemination')"
          :subtitles="[
            animal.tohumlamaTarihi
              ? dayjs(animal.tohumlamaTarihi)
                  .locale(`${$i18n.locale}`)
                  .format('DD.MM.YYYY dddd')
              : '',
            ` ${
              animal.tohumlamaMethod
                ? $t('method') + ': ' + animal.tohumlamaMethod
                : $t('undefined')
            }`,
            ` ${
              animal.tohumlamaStatu
                ? $t('status') + ': ' + animal.tohumlamaStatu
                : $t('undefined')
            }`,
          ]"
          :to="{ name: 'animal-insemination', query: { id: animal.id } }"
        />
      </b-col>
      <b-col
        v-if="animal.genderid == 1"
        cols="12"
        sm="6"
        md="4"
        lg="3"
        class="p-0 px-sm-1 col-xxl-2"
      >
        <animal-action
          :title="$t('birth')"
          :to="{ name: 'animal-birth', query: { id: animal.id } }"
        />
      </b-col>
      <b-col
        v-if="animal.genderid == 1"
        cols="12"
        sm="6"
        md="4"
        lg="3"
        class="p-0 px-sm-1 col-xxl-2"
      >
        <animal-action
          :title="$t('birthHistory')"
          :subtitles="[`Yavru atma`, `01/01/1999`]"
        />
      </b-col>
      <b-col
        v-if="animal.genderid == 1"
        cols="12"
        sm="6"
        md="4"
        lg="3"
        class="p-0 px-sm-1 col-xxl-2"
      >
        <animal-action
          :title="$t('mobility')"
          :subtitles="[
            animal.alarmtarihi
              ? dayjs(animal.mobilitydate)
                  .locale(`${$i18n.locale}`)
                  .format('DD.MM.YYYY dddd')
              : '',
            animal.alarmtype ? $t(animal.alarmtype) : $t('undefined'),
          ]"
          :to="{ name: 'animal-mobility', query: { id: animal.id } }"
        />
      </b-col>
      <b-col cols="12" sm="6" md="4" lg="3" class="p-0 px-sm-1 col-xxl-2">
        <animal-action
          :title="$t('exams')"
          :subtitles="[
            animal.muayeneTarihi
              ? dayjs(animal.muayeneTarihi)
                  .locale(`${$i18n.locale}`)
                  .format('DD.MM.YYYY dddd')
              : '',
            animal.muayeneYapan
              ? $t('examiner') + ': ' + animal.muayeneYapan
              : '',
          ]"
          :to="{ name: 'animal-exam', query: { id: animal.id } }"
        />
      </b-col>

      <b-col cols="12" sm="6" md="4" lg="3" class="p-0 px-sm-1 col-xxl-2">
        <animal-action
          :title="$t('medicinesAndVaccines')"
          :subtitles="[
            animal.asiilacTarihi
              ? dayjs(animal.asiilacTarihi)
                  .locale(`${$i18n.locale}`)
                  .format('DD.MM.YYYY dddd')
              : '',
            ` ${
              animal.asiilacadi
                ? $t('medicineName') + ': ' + animal.asiilacadi
                : ''
            }`,
          ]"
          :to="{ name: 'animal-medicine', query: { id: animal.id } }"
        />
      </b-col>
      <b-col cols="12" sm="6" md="4" lg="3" class="p-0 px-sm-1 col-xxl-2">
        <animal-action
          :title="$t('physicalInfo')"
          :subtitles="[
            animal.weightrecordeddate
              ? dayjs(animal.weightrecordeddate)
                  .locale(`${$i18n.locale}`)
                  .format('DD.MM.YYYY dddd')
              : $t('undefined'),
            ` ${
              animal.weight ? $t('ca') + ': ' + animal.weight : $t('undefined')
            }`,
            ` ${$t('conditionScore')}: ${
              animal.condition ? animal.condition : $t('undefined')
            }`,
          ]"
          :to="{ name: 'animal-weights-conditions', query: { id: animal.id } }"
        />
      </b-col>

      <b-col cols="12" sm="6" md="4" lg="3" class="p-0 px-sm-1 col-xxl-2">
        <animal-action
          :title="$t('groups')"
          :subtitles="animalGroups"
          :to="{ name: 'animal-group', query: { id: animal.id } }"
        />
      </b-col>
      <b-col cols="12" sm="6" md="4" lg="3" class="p-0 px-sm-1 col-xxl-2">
        <animal-action
          :title="$t('feeding')"
          :subtitles="[
            $t('roughage') +
              ': ' +
              (animal.kabaYemMiktari || '-') +
              ' (' +
              (animal.kabaYemTarihi
                ? dayjs(animal.kabaYemTarihi)
                    .locale(`${$i18n.locale}`)
                    .format('DD.MM.YYYY dddd')
                : '-') +
              ')',
            $t('grain') +
              ': ' +
              (animal.yogunYemMiktari || '-') +
              ' (' +
              (animal.yogunYemTarihi
                ? dayjs(animal.yogunYemTarihi)
                    .locale(`${$i18n.locale}`)
                    .format('DD.MM.YYYY dddd')
                : '-') +
              ')',
          ]"
          :to="{ name: 'animal-feeding', query: { id: animal.id } }"
        />
      </b-col>
      <b-col
        v-if="animal.kesimhaneListesinde != 1"
        cols="12"
        sm="6"
        md="4"
        lg="3"
        class="p-0 px-sm-1 col-xxl-2"
      >
        <animal-action
          :title="$t('addToSlaughterhouseList')"
          :subtitles="[``]"
          @click="addSlaughterhouse"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { BRow, BCol, BButton } from "bootstrap-vue";
import AnimalAction from "./AnimalAction.vue";
import dayjs from "dayjs";
import { getUserData } from "@/auth/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "dayjs/locale/tr";
import "dayjs/locale/en";
import "dayjs/locale/ru";
import "dayjs/locale/az";
export default {
  components: {
    BRow,
    BCol,
    BButton,
    AnimalAction,
  },
  data() {
    return {
      dayjs,
      animalGroups: ["", "", ""],
    };
  },
  computed: {
    ...mapState({
      animal: (state) => state.animalsModule.selectedAnimal,
      plant: (state) => state.app.selectedPlantId,
    }),
  },
  watch: {
    animal: {
      handler() {
        if (this.animal.id) this.getGroupsofAnimal(this.animal.id);
      },
      deep: true,
    },
  },

  methods: {
    async getGroupsofAnimal(animalId) {
      try {
        this.animalGroups = ["", "", ""];
        const response = await this.$store.dispatch(
          "animalsModule/fetchGroupsofAnimal",
          animalId
        );
        for (let index = 0; index < response.length; index++) {
          const element = response[index];

          this.animalGroups.splice(
            index % 3,
            1,
            (this.animalGroups[index % 3] += element.groupname + " ")
          );
        }
      } catch (error) {
        this.$toast.error(error.message);
      }
    },
    async addSlaughterhouse() {
      this.$t("selam");
      await this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("notRevert"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("yes"),
        cancelButtonText: this.$t("no"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          var response = this.$store.dispatch(
            "animalsModule/addAnimalToSlaugterHouse",
            {
              userid: getUserData().id,
              companyid: getUserData().companyid,
              plantid: this.plant,
              animalid: this.animal.id,
              listeddate: new Date(),
            }
          );
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("added"),
              icon: "AlertCircleIcon",
              variant: "success",
            },
          });
          return response;
        } else throw Error("iptal");
      });
    },
  },
};
</script>

<style>
.animal-actions {
  display: flex;
  justify-content: start;
  align-items: center;
  overflow: auto;
  width: 100%;
}

.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
</style>
