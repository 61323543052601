<template>
  <div>
    <b-link
      class="d-flex flex-column animal-action-card align-items-start mt-2 rounded"
      no-body
      :to="
        to.name != null
          ? to
          : {
              name: $router.currentRoute.name,
              query: {
                ...$router.currentRoute.query,
                id: $store.state.animalsModule.selectedAnimal.id,
              },
            }
      "
      @click="$emit('click')"
    >
      <div class="d-flex border-bottom p-1 w-100 title-wrapper">
        <h5 :id="id" class="m-0 mr-1 p-0 text-primary">
          {{ title }}
        </h5>
        <feather-icon
          icon="EyeIcon"
          size="18"
          class="ml-auto"
          style="min-width: 20px"
        />
      </div>
      <div
        class="flex-column align-items-start flex-grow-1 d-flex mt-1 px-1 pb-1 w-100"
      >
        <div class="d-flex flex-column sub-items" style="height: 60px">
          <span
            v-for="(sub, index) in subtitles"
            :id="index + id"
            :key="sub + index"
            class="sub"
          >
            {{ sub }}
            <b-popover
              :target="index + id"
              triggers="hover"
              placement="rightbottom"
              delay="400"
            >
              {{ sub }}
            </b-popover>
          </span>
        </div>
      </div>
    </b-link>

    <b-popover :target="id" triggers="hover" placement="top" delay="800">
      {{ title }}
    </b-popover>
  </div>
</template>

<script>
import { BLink, BPopover } from "bootstrap-vue";
export default {
  components: {
    BLink,
    BPopover,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    subtitles: {
      type: Array,
      default: () => [],
    },
    to: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    id() {
      return (
        this.to.name +
        "-" +
        this.title
          .toLowerCase()
          .split(" ")
          .map((x) => encodeURI(x))
          .join("")
      );
    },
  },
  methods: {},
};
</script>

<style scoped lang="scss">
@import "~@core/scss/base/core/colors/palette-variables.scss";
.animal-action-card {
  min-height: 100px;

  background-color: #f4f4f4;
  color: #1f2535;
  transition: all 0.2s ease-in-out;
  border: 1px solid #e6e6e6;

  & h5 {
    transition: all 0.3s ease-in-out;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-weight: bolder;
  }

  &:hover {
    box-shadow: -4px 5px 8px rgba(0, 0, 0, 0.1) !important;
    & h5 {
      text-decoration: underline;
      transform: scale(1.1);
      transform: translateX(5px);

      color: #1f2535 !important;

      .dark-layout & {
        color: #ededed !important;
      }
    }
    color: #1f2535 !important;
  }

  .dark-layout & {
    background-color: #1f2535 !important;
    color: #ededed !important;
    border: none !important;
  }

  .title-wrapper {
    background-color: #f4f4f4;
    border-radius: 5px 5px 0 0;
    transition: all 0.2s ease-in-out;
    .dark-layout & {
      background-color: #1f2535 !important;
    }

    &:hover {
      background-color: #dedede !important;
      .dark-layout & {
        background-color: #2e354b !important;
      }
    }
  }

  .sub-items {
    max-width: 100%;
    .sub {
      max-width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      font-size: 14px;

      color: #1f2535;
      transition: all 0.2s ease-in-out;

      &:hover {
        transform: translate(5px);
        color: #fe671d !important;
        .dark-layout & {
          color: #fe671d !important;
        }
      }
      .dark-layout & {
        color: #ededed !important;
      }
    }
  }
}
</style>
