var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"animal-actions d-flex justify-content-start align-items-center flex-column"},[_c('portal',{attrs:{"to":"animal-actions-button"}},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$store.commit(
          'animalsModule/SET_EDIT_SIDEBAR',
          !_vm.$store.state.animalsModule.isEditAnimalSidebarActive
        )}}},[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm.$t("editAnimal")))])])],1),_c('b-row',{staticClass:"flex-grow-1 justify-content-start align-items-start row-eq-height",staticStyle:{"max-width":"100%"}},[_c('b-col',{staticClass:"p-0 px-sm-1 col-xxl-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('animal-action',{attrs:{"title":_vm.$t('status'),"subtitles":[
          _vm.animal.statusdate
            ? _vm.dayjs(_vm.animal.statusdate)
                .locale(("" + (_vm.$i18n.locale)))
                .format('DD.MM.YYYY dddd')
            : '',

          _vm.animal.statusname ? _vm.animal.statusname : _vm.$t('undefined'),
          _vm.animal.statusgun ? _vm.animal.statusgun : 0 ],"to":{ name: 'animal-status', params: { id: _vm.animal.id } }}})],1),(_vm.animal.genderid == 1)?_c('b-col',{staticClass:"p-0 px-sm-1 col-xxl-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('animal-action',{attrs:{"title":_vm.$t('milk'),"subtitles":[
          _vm.animal.lactation
            ? _vm.$t('lactation') + ': ' + _vm.animal.lactation
            : _vm.$t('undefined'),
          (" " + (_vm.animal.aveGunlukSut
              ? _vm.$t('dailyMilk') + ': ' + _vm.animal.aveGunlukSut
              : _vm.$t('undefined'))),
          (" " + (_vm.animal.aveHaftalikSut
              ? _vm.$t('weeklyMilk') + ': ' + _vm.animal.aveHaftalikSut
              : _vm.$t('undefined'))) ],"to":{ name: 'animal-milk', query: { id: _vm.animal.id } }}})],1):_vm._e(),(_vm.animal.genderid == 1)?_c('b-col',{staticClass:"p-0 px-sm-1 col-xxl-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('animal-action',{attrs:{"title":_vm.$t('insemination'),"subtitles":[
          _vm.animal.tohumlamaTarihi
            ? _vm.dayjs(_vm.animal.tohumlamaTarihi)
                .locale(("" + (_vm.$i18n.locale)))
                .format('DD.MM.YYYY dddd')
            : '',
          (" " + (_vm.animal.tohumlamaMethod
              ? _vm.$t('method') + ': ' + _vm.animal.tohumlamaMethod
              : _vm.$t('undefined'))),
          (" " + (_vm.animal.tohumlamaStatu
              ? _vm.$t('status') + ': ' + _vm.animal.tohumlamaStatu
              : _vm.$t('undefined'))) ],"to":{ name: 'animal-insemination', query: { id: _vm.animal.id } }}})],1):_vm._e(),(_vm.animal.genderid == 1)?_c('b-col',{staticClass:"p-0 px-sm-1 col-xxl-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('animal-action',{attrs:{"title":_vm.$t('birth'),"to":{ name: 'animal-birth', query: { id: _vm.animal.id } }}})],1):_vm._e(),(_vm.animal.genderid == 1)?_c('b-col',{staticClass:"p-0 px-sm-1 col-xxl-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('animal-action',{attrs:{"title":_vm.$t('birthHistory'),"subtitles":["Yavru atma", "01/01/1999"]}})],1):_vm._e(),(_vm.animal.genderid == 1)?_c('b-col',{staticClass:"p-0 px-sm-1 col-xxl-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('animal-action',{attrs:{"title":_vm.$t('mobility'),"subtitles":[
          _vm.animal.alarmtarihi
            ? _vm.dayjs(_vm.animal.mobilitydate)
                .locale(("" + (_vm.$i18n.locale)))
                .format('DD.MM.YYYY dddd')
            : '',
          _vm.animal.alarmtype ? _vm.$t(_vm.animal.alarmtype) : _vm.$t('undefined') ],"to":{ name: 'animal-mobility', query: { id: _vm.animal.id } }}})],1):_vm._e(),_c('b-col',{staticClass:"p-0 px-sm-1 col-xxl-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('animal-action',{attrs:{"title":_vm.$t('exams'),"subtitles":[
          _vm.animal.muayeneTarihi
            ? _vm.dayjs(_vm.animal.muayeneTarihi)
                .locale(("" + (_vm.$i18n.locale)))
                .format('DD.MM.YYYY dddd')
            : '',
          _vm.animal.muayeneYapan
            ? _vm.$t('examiner') + ': ' + _vm.animal.muayeneYapan
            : '' ],"to":{ name: 'animal-exam', query: { id: _vm.animal.id } }}})],1),_c('b-col',{staticClass:"p-0 px-sm-1 col-xxl-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('animal-action',{attrs:{"title":_vm.$t('medicinesAndVaccines'),"subtitles":[
          _vm.animal.asiilacTarihi
            ? _vm.dayjs(_vm.animal.asiilacTarihi)
                .locale(("" + (_vm.$i18n.locale)))
                .format('DD.MM.YYYY dddd')
            : '',
          (" " + (_vm.animal.asiilacadi
              ? _vm.$t('medicineName') + ': ' + _vm.animal.asiilacadi
              : '')) ],"to":{ name: 'animal-medicine', query: { id: _vm.animal.id } }}})],1),_c('b-col',{staticClass:"p-0 px-sm-1 col-xxl-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('animal-action',{attrs:{"title":_vm.$t('physicalInfo'),"subtitles":[
          _vm.animal.weightrecordeddate
            ? _vm.dayjs(_vm.animal.weightrecordeddate)
                .locale(("" + (_vm.$i18n.locale)))
                .format('DD.MM.YYYY dddd')
            : _vm.$t('undefined'),
          (" " + (_vm.animal.weight ? _vm.$t('ca') + ': ' + _vm.animal.weight : _vm.$t('undefined'))),
          (" " + (_vm.$t('conditionScore')) + ": " + (_vm.animal.condition ? _vm.animal.condition : _vm.$t('undefined'))) ],"to":{ name: 'animal-weights-conditions', query: { id: _vm.animal.id } }}})],1),_c('b-col',{staticClass:"p-0 px-sm-1 col-xxl-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('animal-action',{attrs:{"title":_vm.$t('groups'),"subtitles":_vm.animalGroups,"to":{ name: 'animal-group', query: { id: _vm.animal.id } }}})],1),_c('b-col',{staticClass:"p-0 px-sm-1 col-xxl-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('animal-action',{attrs:{"title":_vm.$t('feeding'),"subtitles":[
          _vm.$t('roughage') +
            ': ' +
            (_vm.animal.kabaYemMiktari || '-') +
            ' (' +
            (_vm.animal.kabaYemTarihi
              ? _vm.dayjs(_vm.animal.kabaYemTarihi)
                  .locale(("" + (_vm.$i18n.locale)))
                  .format('DD.MM.YYYY dddd')
              : '-') +
            ')',
          _vm.$t('grain') +
            ': ' +
            (_vm.animal.yogunYemMiktari || '-') +
            ' (' +
            (_vm.animal.yogunYemTarihi
              ? _vm.dayjs(_vm.animal.yogunYemTarihi)
                  .locale(("" + (_vm.$i18n.locale)))
                  .format('DD.MM.YYYY dddd')
              : '-') +
            ')' ],"to":{ name: 'animal-feeding', query: { id: _vm.animal.id } }}})],1),(_vm.animal.kesimhaneListesinde != 1)?_c('b-col',{staticClass:"p-0 px-sm-1 col-xxl-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('animal-action',{attrs:{"title":_vm.$t('addToSlaughterhouseList'),"subtitles":[""]},on:{"click":_vm.addSlaughterhouse}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }